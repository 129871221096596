import type { TMarket } from 'markets-store'
import { useMarketsStore } from './useMarketsStore'

/**
 * Получение списка маркетов события по его id.
 * Можно передать number, ref или getter
 * @example
 * const markets = useMarketsByEventId(123)
 * const otherMarkets = useMarketsByEventId(() => props.eventId)
 */
export function useMarketsByEventId(
  id: number | Ref<number> | (() => number),
): ComputedRef<TMarket[]> {
  const MS = useMarketsStore()

  return computed<TMarket[]>(() => {
    const eventId = toValue(id)
    return MS.getMarketsByEventId(eventId)
  })
}
