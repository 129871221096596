import type { TMatchStats, TPeriodScore, TQualifier } from 'markets-store'
import { MATCH_STATUS_CODES_BY_CRICKET } from '../../composables/useEventServer/constants'

type IGetServingQualifier = (params: {
  matchStatusCode: number
  eventProvider: number
}) => TQualifier | null

const getServingQualifier: IGetServingQualifier = ({
  matchStatusCode,
  eventProvider,
}: {
  matchStatusCode: number
  eventProvider: number
}) => {
  const isServing = (qualifier: TQualifier) =>
    MATCH_STATUS_CODES_BY_CRICKET[qualifier].has(matchStatusCode)

  if (eventProvider !== 1) return null
  if (isServing('home')) return 'home'
  if (isServing('away')) return 'away'
  return null
}

type TFormatCricketStatsForScoreboard = (
  matchStats: TMatchStats,
  matchExtraParams: {
    isShownOverAndDelivery: boolean
    eventProvider: number
    matchStatus?: number
  },
) => {
  totalScores: { [key in TQualifier]: number | undefined }
  periodScores: {
    scores: { [key in TQualifier]: string }
    isLast: boolean
    number: number | string
  }[]
  servingQualifier: TQualifier | null
  icons: { [key in TQualifier]: string | null }
}
/* eslint-disable camelcase */
const formatCricketStatsForScoreboard: TFormatCricketStatsForScoreboard = (
  matchStats,
  matchExtraParams,
) => {
  const {
    isShownOverAndDelivery = false,
    eventProvider,
    matchStatus,
  } = matchExtraParams

  const {
    period_scores = [],
    home_score,
    away_score,
    match_status_code,
    away_delivery,
    home_delivery,
    away_dismissals,
    home_dismissals,
    innings,
  } = matchStats as TMatchStats

  const qualifiers: TQualifier[] = ['home', 'away']

  const servingQualifier = getServingQualifier({
    matchStatusCode: (match_status_code ?? matchStatus) as number,
    eventProvider,
  })

  const totalScores = {
    home: home_score,
    away: away_score,
  }

  const getIcon = (qualifier: TQualifier) => {
    if (!servingQualifier) return null
    return servingQualifier === qualifier ? 'cricket-batt' : 'cricket-ball'
  }

  const icons = {
    home: getIcon('home'),
    away: getIcon('away'),
  }

  if (!period_scores.length) {
    const customPeriodScores = {
      away_delivery,
      home_delivery,
      away_score,
      home_score,
      away_dismissals,
      home_dismissals,
    }

    period_scores.push(customPeriodScores as TPeriodScore)
  }

  const periodScores = period_scores.map(
    (period: TPeriodScore, periodIndex: any) => {
      const isLastPeriod = periodIndex === period_scores.length - 1
      const periodScoreByQualifier: { [key in TQualifier]: string } = {
        home: '',
        away: '',
      }

      qualifiers.forEach((qualifier) => {
        const score = period[`${qualifier}_score`] ?? '-'
        const dismissals = period[`${qualifier}_dismissals`] ?? '-'
        const over = period[`${qualifier}_over`] ?? ''
        const delivery = period[`${qualifier}_delivery`] ?? ''
        const isServing = qualifier === servingQualifier
        const isNeededOverAndDelivery =
          isShownOverAndDelivery &&
          isLastPeriod &&
          isServing &&
          over &&
          delivery

        periodScoreByQualifier[qualifier] = isNeededOverAndDelivery
          ? `${score}/${dismissals} (${over}.${delivery})`
          : `${score}/${dismissals}`
      })

      return {
        scores: periodScoreByQualifier,
        isLast: isLastPeriod,
        number: period.number || innings || '',
      }
    },
  )

  return {
    totalScores,
    periodScores,
    servingQualifier,
    icons,
  }
}

export { getServingQualifier, formatCricketStatsForScoreboard }
