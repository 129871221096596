<template>
  <button
    :data-uniq-id="props.outcome.uniqId"
    :data-event-id="props.outcome.eventId"
    :data-market-id="props.outcome.marketId"
    :data-market-specifiers="props.outcome.specifiersString"
    :data-outcome-id="props.outcome.id"
    data-todo="to_coupon"
    data-t="outcome"
    class="outcome-button"
    :data-is-opened="
      props.marketStatus !== -1 &&
      props.outcome.active &&
      props.outcome.computedState === 'active'
    "
    :class="outcomeButtonClass"
  >
    <div class="outcome-wrapper">
      <span v-if="props.isShownName" class="outcome-name" data-t="name">
        <slot name="name">{{ props.outcome.name }}</slot>
      </span>
      <span class="outcome-odds" data-t="odds">
        <Transition :name="upDownState">
          <span :key="props.outcome.doubleOdds" class="odds-roll">
            {{ props.outcome.doubleOdds }}
          </span>
        </Transition>
      </span>
      <span
        :id="`${props.outcome.uniqId}-state`"
        class="outcome-state"
        :class="upDownState"
      />
    </div>
    <div class="state-border" :class="upDownState" />
  </button>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import type { TOutcome } from 'markets-store/types'
import { MARKET_STATES } from 'markets-store/constants'
import { useCouponStore } from '@st/coupon/stores/useCouponStore'

const { hasOutcome } = useCouponStore()

interface Props {
  outcome: TOutcome
  marketStatus?: number
  size?: 'm' | 'l'
  isShownName?: boolean
  type?: 'gray' | 'ghost'
}

const props = withDefaults(defineProps<Props>(), {
  size: 'm',
  isShownName: false,
  marketStatus: 0,
  type: 'gray',
})

const isLocked = computed(
  () => !props.outcome.active || (props.outcome.id && !props.outcome.odds),
)
const isClosed = computed(
  () =>
    props.marketStatus === MARKET_STATES.BETSTOP ||
    props.outcome.computedState === 'suspend',
)
const isHidden = computed(() => props.outcome.computedState === 'hidden')

const outcomeButtonClass = computed(() => [
  props.size,
  `type-${props.type}`,
  {
    selected: hasOutcome(props.outcome.uniqId),
    closed: isClosed.value,
    locked: isLocked.value,
    hidden: isHidden.value,
    'with-name': props.isShownName,
  },
])

const upDownState = ref<'up' | 'down' | 'fromUp' | 'fromDown' | 'idle'>('idle')
const ODDS_CHANGE_DURATION = 3000
const currentStateOddsCHange = ref<
  'up' | 'down' | 'fromUp' | 'fromDown' | null
>(null)
let oddsDurationTimer: ReturnType<typeof setTimeout>
function resetAnimationState() {
  oddsDurationTimer = setTimeout(() => {
    currentStateOddsCHange.value = null
    upDownState.value = 'idle'
  }, ODDS_CHANGE_DURATION)
}
watch(
  () => props.outcome.odds,
  async (newValue, oldValue) => {
    const direction = newValue < oldValue ? 'down' : 'up'

    if (currentStateOddsCHange.value) {
      upDownState.value = 'idle'
      await delay(1)
      if (currentStateOddsCHange.value !== direction) {
        upDownState.value = direction === 'down' ? 'fromUp' : 'fromDown'
      } else upDownState.value = direction

      clearTimeout(oddsDurationTimer)
      resetAnimationState()
    } else {
      upDownState.value = direction
      currentStateOddsCHange.value = direction

      resetAnimationState()
    }
  },
)

onBeforeUnmount(() => {
  clearTimeout(oddsDurationTimer)
})
</script>

<style scoped>
/* stylelint-disable */
@keyframes oddsUp {
  0% {
    transform: scaleX(0);
    background: linear-gradient(180deg, var(--border-success), transparent 65%);
  }

  15% {
    transform: scaleX(1);
  }

  90% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}
@keyframes oddsDown {
  0% {
    transform: scaleX(0);
    background: linear-gradient(180deg, transparent 50%, var(--border-error));
  }

  15% {
    transform: scaleX(1);
  }

  90% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}
@keyframes oddsFromUpToDown {
  0% {
    transform: scaleX(1);
    background: linear-gradient(180deg, var(--border-success), transparent 50%);
  }

  5% {
    transform: scale(2) rotate(-10deg);
  }

  15% {
    transform: scale(3) rotate(-90deg);
    background: linear-gradient(180deg, var(--border-brand), transparent 50%);
  }

  30% {
    transform: scaleX(3) rotate(-180deg);
    background: linear-gradient(180deg, var(--border-error), transparent 50%);
  }

  99% {
    transform: scaleX(1) rotate(-180deg);
    background: linear-gradient(180deg, var(--border-error), transparent 50%);
  }

  100% {
    transform: scaleX(0);
  }
}
@keyframes oddsFromDownToTop {
  0% {
    transform: scaleX(1);
    background: linear-gradient(180deg, transparent 50%, var(--border-error));
  }

  5% {
    transform: scale(3);
  }

  15% {
    transform: scale(3) rotate(-90deg);
    background: linear-gradient(180deg, transparent 50%, var(--border-brand));
  }

  30% {
    transform: scaleX(3) rotate(-180deg);
    background: linear-gradient(180deg, transparent 50%, var(--border-success));
  }

  95% {
    transform: scaleX(1) rotate(-180deg);
    background: linear-gradient(180deg, transparent 50%, var(--border-success));
  }

  100% {
    transform: scaleX(0);
  }
}
/* stylelint-enable */

.outcome-name {
  overflow: hidden;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.outcome-odds {
  position: relative;

  overflow: hidden;
  display: flex;

  /* stylelint-disable */
  font-size: 14px;
  font-weight: 800;
  font-style: italic;
  line-height: 16px;
  letter-spacing: 0.26px;
  /* stylelint-enable */
}

.outcome-state {
  pointer-events: none;

  position: absolute;
  inset: 0;

  opacity: 0;
  border-radius: var(--border-radius-100);
}

.outcome-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  background: var(--button-gray-default);
  border-radius: var(--border-radius-100);
}

.state-border {
  pointer-events: none;

  position: absolute;
  z-index: -1;
  inset: 0;
  transform-origin: center;
  transform: scaleX(0);

  width: 100%;
  height: 100%;

  border-radius: var(--border-radius-100);

  transition: transform 0.2s ease-in-out;
}

.outcome-button {
  cursor: pointer;

  position: relative;
  z-index: 0;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 100%;
  min-width: 52px;
  min-height: 16px;
  padding: 1.5px; /* stylelint-disable-line */

  font-family: var(--sf-pro-display);
  color: var(--text-primary);

  background: var(--button-gray-default);
  border: none;
  border-radius: var(--border-radius-100);
  outline: none;
  box-shadow: none;

  .outcome-state {
    display: none;
  }

  &.type-ghost {
    background-color: var(--button-ghost-default);

    .outcome-wrapper {
      background-color: transparent;
    }

    .outcome-state {
      display: block;
    }

    .state-border {
      display: none;
    }
  }

  &.m {
    min-height: 32px;

    .outcome-wrapper {
      padding: var(--spacing-075) var(--spacing-050);
    }
  }

  &.l {
    min-height: 40px;

    .outcome-wrapper {
      padding: var(--spacing-125) var(--spacing-050);
    }
  }

  &.with-name {
    .outcome-wrapper {
      justify-content: space-between;
    }

    &.m {
      .outcome-wrapper {
        padding: var(--spacing-075) 9px; /* stylelint-disable-line */
      }
    }

    &.l {
      .outcome-wrapper {
        padding: var(--spacing-125) var(--spacing-125);
      }
    }
  }

  > div,
  > span {
    pointer-events: none;
  }

  &.selected {
    background: var(--button-primary-default);
    /* stylelint-disable-next-line */
    .outcome-wrapper {
      background: var(--button-primary-default);
    }

    .outcome-name {
      color: var(--text-secondary);
    }

    &.type-ghost {
      color: var(--text-primary);
      background: var(--background-ghost-primary);

      .outcome-wrapper {
        background: transparent;
      }

      .outcome-name {
        color: var(--text-primary);
      }
    }

    .outcome-state {
      &.up,
      &.down {
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  &.locked,
  &.closed {
    pointer-events: none;
    opacity: 0.4;
  }

  &.locked {
    /* stylelint-disable */
    .outcome-odds {
      font-size: 0;

      &::after {
        content: ' – ';
        font-size: 14px;
        line-height: 16px;
      }
    }

    /* stylelint-enable */
  }

  &.hidden {
    display: none;
  }
}

.outcome-button:not(.selected, .locked, .closed) {
  .outcome-state {
    &.up,
    &.down {
      z-index: -1;
      opacity: 1;

      &::before {
        content: '';

        position: absolute;
        right: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 100%;
        margin: auto;

        border-radius: var(--border-radius-100);
      }
    }

    &.up {
      &::before {
        top: 0;
        border-top: 2px solid var(--system-success);
      }
    }

    &.down {
      &::before {
        bottom: 0;
        border-bottom: 2px solid var(--system-error);
      }
    }
  }

  .state-border {
    &.up {
      animation: oddsUp 3200ms ease-in-out 1 forwards;
    }

    &.down {
      animation: oddsDown 3200ms ease-in-out 1 forwards;
    }

    /* stylelint-disable */
    &.fromUp {
      animation: oddsFromUpToDown 3200ms ease-in-out 1 forwards;
    }

    &.fromDown {
      animation: oddsFromDownToTop 3200ms ease-in-out 1 forwards;
    }
    /* stylelint-enable */
  }
}

.outcome-button:not(.selected) {
  &:hover {
    background-color: var(--button-gray-hover);

    .outcome-wrapper {
      background-color: var(--button-gray-hover);
    }
  }

  &.type-ghost:hover {
    background-color: var(--button-ghost-hover);

    .outcome-wrapper {
      background-color: transparent;
    }
  }
}

.up-enter-active,
.down-enter-active,
.from-up-enter-active,
.from-down-enter-active,
.idle-enter-active {
  transition: transform 0.35s;
}

.up-leave-active,
.down-leave-active,
.from-up-leave-active,
.from-down-leave-active,
.idle-leave-active {
  position: absolute;
  transition: transform 0.35s ease-in-out;
}

.up-enter-from,
.from-up-enter-from,
.idle-enter-from {
  transform: translateY(1.2em);
}

.up-leave-to,
.from-up-leave-to,
.idle-leave-to {
  transform: translateY(-1.2em);
}

.down-enter-from,
.from-down-enter-from {
  transform: translateY(-1.2em);
}

.down-leave-to,
.from-down-leave-to {
  transform: translateY(1.2em);
}
</style>
