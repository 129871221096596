export const MATCH_STATUS_CODES_BY_BASEBALL = {
  home: new Set([
    401, 403, 405, 407, 409, 411, 413, 415, 417, 419, 422, 424, 426, 428, 430,
    432, 434, 436, 438,
  ]),
  away: new Set([
    402, 404, 406, 408, 410, 412, 414, 416, 418, 420, 421, 423, 425, 427, 429,
    431, 433, 435, 437,
  ]),
}

export const MATCH_STATUS_CODES_BY_CRICKET = {
  home: new Set([501, 503, 506]),
  away: new Set([502, 504, 507]),
}
